import ApiService from '../services/ApiService';

// the main super class of Socket Clients (Speaker Client & Listener Client)
class SocketClient{

    constructor(socket_endpoint, username, socket_type, stream_id, language){
        this.socket_endpoint = socket_endpoint;
        this.username = username;
        this.socket_type = socket_type;
        this.stream_id = stream_id;
        this.language = language;
        this.socket = null;
        this.handle_messages = () => {}
    }

    #add_default_event_listeners(){
        // send the intitation message when socket connection opens
        this.socket.addEventListener('open', () => {

            console.log(`Connected to WebSocket server (stream_id: ${this.stream_id})`);

            const message = `${this.username}:${this.stream_id}:${this.socket_type}:${this.language}`;
            this.socket.send(message);
        });

        this.socket.addEventListener('close', () => {
            console.log('Disconnected from WebSocket server');
        });
    }

    initialize(){
        if(this.socket==null){
            this.socket = new WebSocket(this.socket_endpoint);
            this.#add_default_event_listeners();
            this.handle_messages();
        }
    }

    close(){
        if(this.socket){
            this.socket.close();
            this.socket = null;
        }
    }

}

// listener websocket client
class ListenerClient extends SocketClient {

    constructor(socket_endpoint,username, stream_id, language){
        super(socket_endpoint, username, 'listener', stream_id, language);
        this.handle_messages = this.#handle_messages
        this.handle_audio = (audio) => {}
        this.handle_text = (text) => {}
        this.onAudio = (audio_action) => { this.handle_audio = audio_action}
        this.onText = (text_action) => { this.handle_text = text_action}
    }

    #handle_messages(){
        this.socket.addEventListener('message', (event) => {
            // received an audio file (Text to Speech Audio)
            if (event.data instanceof Blob) {
    
                const audioElement = new Audio();
                const audioBlob = event.data;
                const blobUrl = URL.createObjectURL(audioBlob);
                audioElement.src = blobUrl;
                this.handle_audio(audioElement);
                // received translation text
            } else {
                const text = event.data;
                this.handle_text(text);
            }
        });
    }
}

// speaker websocket client
class SpeakerClient extends SocketClient {

    constructor(socket_endpoint,username, stream_id, stream_title, language){
        super(socket_endpoint, username, 'speaker', stream_id, language);
        this.stream_title = stream_title
        this.handle_messages = this.#handle_messages
        this.handle_text = (text) => {}
        this.onText = (text_action) => { this.handle_text = text_action}
    }

    setTitle(title){
        this.stream_title = title;
        const api = ApiService.getInstance();
        const url = `ws/title?stream_id=${this.stream_id}&title=${this.stream_title}`
        api.post(url).then((res) => console.log(`${this.stream_id}: ${res.data.msg}`))
        .catch((err) => console.log(err));
    }

    #handle_messages(){
        this.socket.addEventListener('message', (event) => {
            const text = event.data;
            this.handle_text(text);
        });
    }

    initialize(){
        super.initialize();
        if(this.socket !== null){
            this.setTitle(this.stream_title);
        }
    }

    sendAudio(audio_chunk){
        const audioChunks = (this.meta_chunk == null ? [audio_chunk] : [this.meta_chunk, audio_chunk])
        if(this.meta_chunk==null) {this.meta_chunk = audio_chunk; }
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        this.socket.send(audioBlob);
    }

    reset(){
        this.meta_chunk = null;
    }
}

//class to create Socket connection to the websocket server
// this allows connection and creation of streams (both speakers & listeners)
class SocketService {

    static SOCKET_ENDPOINT = 'wss://jisr-backend.thelaunchpad.ae/ws';

    // generates unique stream id
    static generateStreamID(){
        return `stream-${Date.now()%1000000}`;
    }

    // create a listener websocket client
    static createListener(username, stream_id, language){
        return new ListenerClient(this.SOCKET_ENDPOINT, username, stream_id, language);
    }

    //create a speaker websocket client
    static createSpeaker(username, stream_id, stream_title, language){
        return new SpeakerClient(this.SOCKET_ENDPOINT, username, stream_id, stream_title, language);
    }
}


export default SocketService