import React, { useContext } from 'react'
import { useLoading } from '../context/LoadingContext'

const LoadingSpinner = () => {
	const { isLoading } = useLoading()

	if (!isLoading) {
		return null
	}

	return (
		<div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black/80 bg-opacity-50">
			<svg
				class="animate-spin h-12 w-12 text-primary"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					class="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					stroke-width="4"
				></circle>
				<path
					class="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				></path>
			</svg>
		</div>
	)
}

export default LoadingSpinner

// import Lottie from 'react-lottie-player'
// import React, { useState, useEffect } from 'react'
// import lottieJson from '../assets/loading_lottie.json'
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import './Loading.css'

// export default function Loading() {
//     const [loading, setLoading] = useState(true);
//     let navigate = useNavigate();

//     useEffect(() => {
//         // Make an Axios call here with the same endpoint
//         //navigate('/gallery');
//         axios.post('/your-backend-api-endpoint', { /* data */ })
//             .then((response) => {
//                 // Handle the response here
//                 console.log(response.data);
//                 // If the request is successful, navigate to the next page
//                 navigate('/gallery');
//                 // Set loading to false to stop displaying the loading animation
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 // Handle any errors here
//                 console.error('Error:', error);
//                 // Set loading to false to stop displaying the loading animation
//                 setLoading(false);
//             });
//     }, []); // Empty dependency array to ensure useEffect runs only once

//   return (
//     <div className='Loading-section'>
//         <Lottie
//             loop
//             animationData={lottieJson}
//             play
//             style={{ width: 300, height: 300 }}
//     />
//     This may require a few moments. <br/>Kindly wait until the task is completed.
//     </div>
//   )
// }
