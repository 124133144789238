import axios from 'axios';


// singleton class to do API calls to the Flask API
class ApiService {

    constructor() {
        if (!ApiService.instance) {
            this.api_endpoint = 'https://jisr-backend.thelaunchpad.ae'
            ApiService.instance = this;
        }
        return ApiService.instance;
    }

    static getInstance(){
        return new ApiService();
    }

    url(url_path) {
        return `${this.api_endpoint}/${url_path}`
    }

    get(url_path) {
        return axios.get(this.url(url_path));
    }

    post(url_path, form_data = null) {
        return axios({
            method: "post",
            url: this.url(url_path),
            data: form_data,
            headers: { "Content-Type": "multipart/form-data" },
        });
    }

    createFormData(data_dict) {
        let formData = new FormData()
        for (const [key, value] of Object.entries(data_dict)) {
            formData.append(key, value);
        }
        return formData
    }
}

export default ApiService