import React, { useState } from 'react'
import email_icon from "../assets/mail.svg"
import password_icon from "../assets/lock.svg"
import bgImage from '../assets/images/bg.png'
import { ReactComponent as LaunchpadLogo } from '../assets/images/lp_logo.svg';

import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { useLoading } from '../context/LoadingContext';
import { useStore } from '../context/StoreContext';

const SignUp = () => {
    const { setIsLoading } = useLoading();
    const { setData } = useStore();

    const [values, setValues] = useState({
        email: '',
        password: '',
        name: '',
    });

    const navigate = useNavigate()
    const [errors, setErrors] = useState([])
    const handleInput = (event) => {
        setValues(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setErrors([])
        console.log('HERE')
        console.log('values ====>', values)
        // setErrors(LoginValidation(values))
        console.log('values ====>', values, errors)
        // if(!errors.password) {
            await axios.post('https://jisr-backend.thelaunchpad.ae/signup', values)
            .then(res => {
                setIsLoading(false);
                console.log(res?.data)
                if(res?.data?.message=="signup was successful"){
                    console.log("Account created successfully!")
                    setData(prev => ({...prev, email: res?.data?.email, session_id: res?.data?.session_id}))
                    localStorage.setItem('email', res?.data?.email)
                    localStorage.setItem('session_id', res?.data?.session_id)
                    navigate('/'); 
                }
                else if(res?.data?.message=="signup was unsuccessful"){
                    setIsLoading(false);
                    console.log("The email has already been used!")
                    let status = "The email has already been used"
                    let errors = []
                    errors.push(status)
                    setErrors(errors)
                }
                else{
                    setIsLoading(false);
                    console.log("An error has occurred")
                    let status = "An error has occurred"
                    let errors = []
                    errors.push(status)
                    setErrors(errors)
                }
                  
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err)
                let errors = []
                errors.push(err)
                setErrors(errors)
            });
        // }
    }
    
    return (
        <div className='full-screen-wrapper flex-col gap-2 !bg-cover' style={{background: `url(${bgImage})`}}>
            <div className='flex flex-col gap-5 items-center justify-center py-5'>
                <LaunchpadLogo className='h-[120px] max-w-max' />
                <p className="text-white uppercase text-[30px] not-italic font-bold leading-[normal] tracking-[-0.8px]">
                    Live transcriber
                </p>
            </div>
            <div className="content-box ">
                    <div className="flex flex-col gap-5 w-full">
                        <h2 className="text-3xl font-bold text-white">Sign Up</h2>
                        <div className="w-full">
                            <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className='input-col-group gap-5'
                                onSubmit={handleSubmit}
                            >
                                <div className='input-col-group'>
                                <label for="Name" className='input-label'>Name</label>
                                <input
                                    type="name"
                                    className="input-field"
                                    name="name"
                                    placeholder="Enter your name"
                                    onChange={handleInput}
                                />
                                <div className='input-col-group'>
                                <label for="Email" className='input-label'>Email</label>
                                <input
                                    type="email"
                                    className="input-field"
                                    name="email"
                                    placeholder="Enter your email"
                                    onChange={handleInput}
                                />
                                </div>
                                </div>
                                <div className='input-col-group'>
                                    <label for="password"  className='input-label'>Password</label>
                                    <input
                                        type="password"
                                        className="input-field"
                                        name="password"
                                        placeholder="Enter your password"
                                        onChange={handleInput}
                                    />
                                    {errors.length ? <div className='input-helper input-error'>{errors.join(', ')}</div> : null}
                                </div>
                                <input
                                    type="submit"
                                    value="Sign up"
                                    data-wait="Please wait..."
                                    className="btn btn-primary"
                                    
                                />
                            </form>
                        </div>
                        <div className='text-white/50'>
                            Have an account? <a href="/login" className="btn-link">Log in</a>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default SignUp
