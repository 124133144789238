import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Layout from './Layout.jsx';
import Modal from './Modal.jsx';

import { useStore } from '../context/StoreContext';

import play from '../assets/images/play.png';
import StreamCard from './StreamCard.jsx';

import ApiService from '../services/ApiService'

function Home() {
  const { data } = useStore();
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [showNewStreamModal, setShowNewStreamModal] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate(); // Access the history object
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [streamList, setStreamList] = useState([])
  const [streamTitle, setStreamTitle] = useState('')
  const [languages, setLanguages] = useState([
    {
      name: 'English',
      code: 'en'
    },
    {
      name: 'French',
      code: 'fr'
    },
    {
      name: 'Arabic',
      code: 'ar'
    }
  ])


  useEffect(() => {
    // if (!data.email) {
    //   navigate('/login');
    // }
    getStreams();

  }, []);

  const getStreams = async (e) => {
    const api = ApiService.getInstance()
    const res = await api.get('ws/streams');
    console.log('res ========>', res?.data?.streams)
    if(res?.data?.streams?.length){
        setStreamList(res?.data?.streams)
    }else {
      setStreamList([])
    }
  }

  const handleSubmit = (e) => {
    const enteredUrl = e.target.value;
    setUrl(enteredUrl);

    const regex =
      /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    const isValid = regex.test(enteredUrl) && !enteredUrl.includes('/results?search_query=');
    setIsValidUrl(isValid);
  };

  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
  };

  const handleTitleChange = (event) => {
    const selectedValue = event.target.value;
    setStreamTitle(selectedValue);
  };


  const playStream = (id) => {
    console.log(id)
    // if (e.keyCode === 13) {
    //   handleSubmit(e);
    // }
  };

  const createNewStream = () => {
    setShowNewStreamModal(false)
    const state = {
      username: data?.email,
      language: selectedLanguage,
      title: streamTitle
    };
    navigate(`/new-live-stream`, { state })
  }
  

  return (
    <Layout scrollable={true}>
      <div className='flex items-center justify-between'>
        <p className='text-white text-[32px] font-bold leading-[normal] tracking-[-0.64px]'>Watch now</p>
        <button onClick={() => setShowNewStreamModal(true)} className='btn btn-primary max-w-max'>Start your own livestream</button>
      </div>
      <div className="grid grid-cols-4 items-center gap-8 w-full text-white">
        {streamList.length ? <>
          {streamList?.map((stream, index) => (
            <StreamCard key={index} stream={stream} playStream={playStream} />
          ))}
        </> : <p className='col-span-4 text-white text-xl font-medium text-center flex items-center justify-center w-full h-96'>
        No streams available!
        </p>}
      </div>
      {showNewStreamModal && <Modal title='Start Live Stream'  handleClose={() => setShowNewStreamModal(false)}>
          <form onSubmit={createNewStream} className='flex flex-col gap-5 w-full'> 
            <div className='input-col-group !gap-2'>
              <label htmlFor="language">Stream Title <span className='text-red-500'>*</span></label>
              <input 
                className='input-field-2' 
                type="text" 
                value={streamTitle} 
                onChange={handleTitleChange} 
                required
                placeholder='Enter a title'  
              />
            </div>
            <div className='input-col-group !gap-2'>
              <label htmlFor="language">Select Output Language:</label>
              <select
                id="language"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                className='input-field-2'
              >
                <option value="" disabled>
                  Select a language
                </option>
                {languages.map((language) => (
                  <option key={language.code} value={language.code}>
                    {language.name}
                  </option>
                ))}
              </select>
              <p className='text-[#0A0846] text-sm font-normal leading-[normal] tracking-[-0.28px]'>
                This can be changed to another language after joining.
              </p>
            </div>
            <button type='submit' className='btn btn-primary'>
              Start live
            </button>
          </form>
      </Modal>}
    </Layout>
  );
}

export default Home;
