import React, { useState, setState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LaunchpadLogo } from '../assets/images/lp_logo_horizontal.svg';
import user from '../assets/images/user.png';
import { useStore } from '../context/StoreContext';
import axios from 'axios'

const Navbar = () => {
  const location = useLocation();
  const { data, setData } = useStore();
  const navigate = useNavigate()
  
  useEffect(() => {
    if(localStorage?.getItem('session_id') !== null){
      setData(prev => ({...prev, email: localStorage.getItem('email')}))
    }
  }, [])

  const handleClick = async() => {
    // await axios.post('https://jisr-backend.thelaunchpad.ae/logout', data?.email)
    //   .then(res => {
    //       console.log(res?.data)
    //       if(res?.data?.message=="login was successful"){
    //           console.log("Logged out")
              
    //       }
    //       else if(res?.data?.message=="login was unsuccessful"){
    //         console.log("An error has occurred")
    //       }
    //   }).catch((error) => {
    //     console.log(error)
    //   })

    localStorage.removeItem('email')
    localStorage.removeItem('session_id')
    navigate('/login')
  };

  const hideNavbar = location.pathname === '/signup' || location.pathname === '/login';

  if (hideNavbar) {
    return null;
  }
  
  return (
    <div className='fixed top-0 z-50 w-full py-4 flex items-center justify-between gap-3 px-8 backdrop-blur-sm'>
        <LaunchpadLogo className='h-[70px] max-w-max' />
        <a href="/" className="text-white uppercase text-[40px] not-italic font-bold leading-[normal] tracking-[-0.8px]">
          Live transcriber
        </a>
        <div className='flex items-center gap-5'>
          <div className="flex items-center gap-2 text-sm text-white">
              <img src={user} alt="user" className='h-9 w-9' />
              <span className='flex flex-col items-start'>
                <span>{data?.email}</span>  
                <button onClick={handleClick} className='text-primary font-bold text-xs'>Logout</button>

              </span>
          </div>
        </div>
    </div>
  );
};

export default Navbar;
