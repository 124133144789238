import React from 'react'
import bgImage from '../assets/images/bg.png'
import short_icon from '../assets/YT_shorts.png'

function Layout({pageHeading, children, scrollable}) {
    
  return (
    <div className={`${scrollable ? 'h-screen overflow-y-auto pb-10' : 'h-screen'} !bg-cover`} style={{background: `url(${bgImage})`}}>
        <div className={`${scrollable ? 'pb-10' : 'h-screen pb-5'} flex flex-col gap-5 px-8 pt-28`}>
          {children}
        </div>
    </div>
  )
}

export default Layout
