import { useState, useEffect, useRef } from "react"
import crossImage from '../assets/images/cross.png'


const Modal = ({ title, handleClose, children }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto p-5">
      <div className="min-h-screen h-full w-full">
        <div onClick={handleClose} className="fixed z-10 inset-0 transition-opacity">
          <div className="absolute inset-0 z-0 bg-secondary bg-opacity-50"></div>
        </div>
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex flex-col gap-5 bg-white rounded-[26px] w-full max-w-xl mx-auto relative z-50 p-3 md:p-8">
            <h1
              onClick={handleClose}
              className="flex items-start justify-between text-[#0A0846] text-[32px] font-bold leading-[normal] tracking-[-0.64px]"
              >
              <span>{title}</span>
              <button onClick={handleClose} className="shrink-0 mt-2">
                <img src={crossImage} alt="" />
              </button>
            </h1>
            <div className="flex flex-col gap-5 w-full">
              {children}
            </div>
          </div>
        </div>    
      </div>
    </div>
  )
}

export default Modal