import React, { createContext, useState, useContext } from 'react'

const StoreContext = createContext()

export const useStore = () => {
	return useContext(StoreContext)
}

export const StoreProvider = ({ children }) => {
	const [data, setData] = useState({})

	return (
		<StoreContext.Provider value={{ data, setData }}>
			{children}
		</StoreContext.Provider>
	)
}
