import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import Layout from './Layout.jsx';
import Modal from './Modal.jsx';
import LogoutIcon from '../assets/images/logout.png'
import PlayIcon from '../assets/images/play-button.png'
import PauseIcon from '../assets/images/pause-button.png'
import soundWave from '../assets/images/sound-wave.svg'

import { useStore } from '../context/StoreContext';
import SocketService from '../services/SocketService';

function SpeakerLiveStream() {
  const { data } = useStore();
  const location = useLocation();
  const { state } = location;
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const [showExitStreamModal, setShowExitStreamModal] = useState(false);
  const navigate = useNavigate(); // Access the history 
  
  const [transcription, setTranscription] = useState('');
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const socketRef = useRef(null);

  useEffect(() => {
    async function setupRecorder(){
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = event => {
        const chunk = event.data;
        socketRef.current.sendAudio(chunk);
      };
      recognitionRef.current = mediaRecorder;
    }

    if(socketRef.current == null){
      console.log("The stream language: ", state?.language)
      socketRef.current = SocketService.createSpeaker(state?.username, SocketService.generateStreamID(), state?.title, state?.language);
      console.log(socketRef.current);
      socketRef.current.initialize();
      socketRef.current.onText((transc) => {
        console.log('received transcription');
        setTranscription(transcription => `${transcription}\n${transc}`);
      })
      setupRecorder();
    }
  }, []);

  const toggleTranscription = () => {
    if (recognitionRef.current) {
      if (isListening) {
        recognitionRef.current.stop();
        setIsListening(false);
      } else {
        recognitionRef.current.start(5000); // 5000 milliseconds = 5 seconds chunk size
        setIsListening(true);
      }
    }
  };

  useEffect(() => {
    // if (!data.email) {
    //   navigate('/login');
    // }
  }, []);

  const playStream = (id) => {
    console.log(id)
    // if (e.keyCode === 13) {
    //   handleSubmit(e);
    // }
  };

  return (
    <Layout notScrollable={true}>
      <div className='flex items-center justify-between'>
        <p className='text-white text-[32px] font-bold leading-[normal] tracking-[-0.64px]'>Transcript</p>
        <button onClick={() => setShowExitStreamModal(true)} className='max-w-max'>
          <img src={LogoutIcon} alt="" />
        </button>
      </div>
      <div className="flex flex-col gap-5 h-full">
        <p className='p-6 rounded-lg bg-white/10 text-white !h-[calc(100%_-_100px)] overflow-y-auto'>
          {transcription}
        </p>
        <div className='flex items-center gap-3'>
          <button className='shrink-0' onClick={toggleTranscription}>
            <img src={isListening ? PauseIcon : PlayIcon} alt={isListening ? 'Stop' : 'Speak'} />
          </button>
          <div className={`relative flex overflow-hidden bg-primary py-4 rounded-lg`}>
              <img className={`${isListening ? 'soundwave' : ''}`} src={soundWave} alt="" />
              <img className={`${isListening ? 'soundwave' : ''}`} src={soundWave} alt="" />
              <img className={`${isListening ? 'soundwave' : ''}`} src={soundWave} alt="" />
            </div>
        </div>
      </div>
      {showExitStreamModal && <Modal title='Are you sure you want to leave this live stream?'  handleClose={() => setShowExitStreamModal(false)}>
          <> 
            <div className='flex items-center gap-5'>
              <button onClick={() => setShowExitStreamModal(false)} className='w-1/2 btn btn-outline-primary'>
                Cancel
              </button>
              <button onClick={() => {setShowExitStreamModal(false);socketRef.current.close();navigate('/');}} className='w-1/2 btn btn-primary'>
                Leave
              </button>
            </div>
          </>
      </Modal>}
    </Layout>
  );
}

export default SpeakerLiveStream;
