import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import Layout from './Layout.jsx';
import AudioTranslation from './AudioTranslation.jsx';
import Modal from './Modal.jsx';
import LogoutIcon from '../assets/images/logout.png'

import { useStore } from '../context/StoreContext';
import SocketService from '../services/SocketService';


function ListenerLiveStream() {
  const { data } = useStore();
  const [translation, setTranslation] = useState('');
  const [showExitStreamModal, setShowExitStreamModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('ar');
  const location = useLocation();
  const { state } = location;
  const [languages, setLanguages] = useState([
    {
      name: 'English',
      code: 'en'
    },
    {
      name: 'French',
      code: 'fr'
    },
    {
      name: 'Arabic',
      code: 'ar'
    }
  ])
  const navigate = useNavigate();
  const socketRef = useRef(null);

  const setupListener = (lang) => {
    if(socketRef.current == null){
      socketRef.current = SocketService.createListener(state?.username, state?.stream_id,lang);
      console.log(socketRef.current);
      socketRef.current.initialize();
      socketRef.current.onText((translation) => {
        console.log('transalation received!');
        setTranslation(trans => `${trans}${translation}\n`);
      });
      socketRef.current.onAudio((audio) => {
        console.log('text to speech received!');
        audio.play().then(() => console.log('playing stream audio...'))
        .catch((err) => console.error('error playing stream audio:', err));
      })
    }
  };


  useEffect(() => {
    setupListener(selectedLanguage);
  }, []);

  
  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
    // API call that fetch translation in selected language and set it.
    socketRef.current.close();
    socketRef.current = null;
    setupListener(selectedValue);
  };

  useEffect(() => {
    // if (!data.email) {
    //   navigate('/login');
    // }
  }, []);

  return (
    <Layout notScrollable={true}>
      <div className='flex items-center justify-between'>
        <p className='text-white text-[32px] font-bold leading-[normal] tracking-[-0.64px]'>Translation</p>
        <div className='flex items-center gap-5'>
          <select
            id="language"
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className='input-field-2 bg-white'
          >
            <option value="" disabled>
              Select a language
            </option>
            {languages.map((language) => (
              <option key={language.code} value={language.code}>
                {language.name}
              </option>
            ))}
          </select>
          <button onClick={() => setShowExitStreamModal(true)} className='max-w-max'>
            <img src={LogoutIcon} alt="" />
          </button>
        </div>
      </div>
      <AudioTranslation translation={translation} />
      {showExitStreamModal && <Modal title='Are you sure you want to leave this live stream?'  handleClose={() => setShowExitStreamModal(false)}>
          <> 
            <div className='flex items-center gap-5'>
              <button onClick={() => setShowExitStreamModal(false)} className='w-1/2 btn btn-outline-primary'>
                Cancel
              </button>
              <button onClick={() => {setShowExitStreamModal(false);socketRef.current.close();navigate('/')}} className='w-1/2 btn btn-primary'>
                Leave
              </button>
            </div>
          </>
      </Modal>}
    </Layout>
  );
}

export default ListenerLiveStream;
