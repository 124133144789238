import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import SignUp from './components/SignUp'
import Login from './components/Login'
import Navbar from './components/Navbar'
import LoadingSpinner from './components/LoadingSpinner'
import LiveStreams from './components/LiveStreams'

import { LoadingProvider } from './context/LoadingContext'
import { StoreProvider } from './context/StoreContext'
import SpeakerLiveStream from './components/SpeakerLiveStream'
import ListenerLiveStream from './components/ListenerLiveStream'


const ProtectedRoute = ({ element }) => {
	const isAuthenticated = localStorage.getItem('session_id') !== null;
  
	return isAuthenticated ? element : <Navigate to="/login" />;
  };

function App() {
	return (
		<>
			<StoreProvider>
				<LoadingProvider>
					<BrowserRouter>
						<div className='h-screen overflow-hidden'>
							<Navbar />
							<Routes>
								<Route path="/login" element={<Login />}></Route>
								<Route path="/signup" element={<SignUp />}></Route>
								<Route path="/" element={<ProtectedRoute element={<LiveStreams />} />}></Route>
								<Route path="/new-live-stream" element={<ProtectedRoute element={<SpeakerLiveStream />} />}></Route>
								<Route path="/live-streams/:id" element={<ProtectedRoute element={<ListenerLiveStream />} />}></Route>
							</Routes>
							<LoadingSpinner />
						</div>
					</BrowserRouter>
				</LoadingProvider>
			</StoreProvider>
		</>
	)
}

export default App
