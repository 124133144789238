import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import { useStore } from '../context/StoreContext';

import play from '../assets/images/play.png';

// import { data } from '../context/StoreContext';

function StreamCard({stream, playStream}) {
  const { data } = useStore();
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate(); // Access the history object

  const handleNavigation = (id) => {
    const state = {
      username: stream?.username,
      stream_id: stream?.stream_id,
      stream_title: stream?.stream_title,
    };

    navigate(`/live-streams/${id}`, { state });
  };

  return (
    <div className='card' onClick={() => handleNavigation(stream?.stream_id)}>
          <h3 className='text-white font-bold leading-[normal] tracking-[-0.32px]'>
            {stream?.stream_title} - {stream?.stream_id}
          </h3>
          <button onClick={() => playStream(stream?.stream_id)} className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2'>
            <img src={play} alt="play" className='h-10 w-10' />
          </button>
          <div className='flex items-center gap-5 justify-between'>
            <p className='text-white text-sm font-medium leading-[normal] tracking-[-0.28px]'>{stream?.username}</p>
            <p className='flex justify-center items-center gap-2 px-4 py-1 rounded-lg bg-red-500 text-white text-xs font-medium leading-[normal] tracking-[-0.24px]'>
              LIVE
            </p>
          </div>
        </div>
  );
}

export default StreamCard;
