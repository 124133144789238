import React, { useState, useEffect, useRef } from 'react';

import PlayIcon from '../assets/images/play-button.png';
import PauseIcon from '../assets/images/pause-button.png';
import soundWave from '../assets/images/sound-wave.svg';

const AudioTranslation = ({translation}) => {

  return (
    <div className="flex flex-col gap-5 h-full">
      <p className='p-6 rounded-lg bg-white/10 text-white !h-[calc(100%_-_20px)] overflow-y-auto'>
        {translation}
      </p>
    </div>
  );
};

export default AudioTranslation;